@font-face {
  font-family: 'password';
  src: url("../../assets/fonts/password.ttf") format("truetype"); }

%input {
  background-color: #fff !important;
  border: 1px solid #ebebebde !important;
  &::placeholder {
    font-family: 'Arial';
    font-size: 16px;
    font-weight: 400; } }

.password-input {
  @extend %input;
  font-family: 'password'; }

.login-input {
  @extend %input; }

.toggler {
  width: 20px;
  cursor: pointer;
  right: 10px;
  position: absolute;
  top: calc(50% - 12px); }
