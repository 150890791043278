.dropdown-menu {
    transform: translate3d(-150px, 7px, 0px) !important;
    &.reverse {
        transform: translate3d(130px, 7px, 0px) !important; } }
.dropdown-toggle {
    position: relative;
    left: 100px;
    top: 20px; }

.popup {
    margin-bottom: 20px;
    margin: 5px auto;
    .spotlight-description {
        top: 1.75%;
        right: calc( 6% + 30px ); }
    .spotlight {
        left: 110px;
        top: 1%;
        text-align: end;
        right: 2%;
        .show {
            div {
                transform: translate3d(85px, 15px, 0px) !important; } } } }
